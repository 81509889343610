/**
 * ADDI - UI-Foundation
 * Do not edit directly
 * Generated on Fri, 28 Feb 2025 16:04:29 GMT
 */

module.exports = {
  "FontAddiFamilyPrimaryName": "Mallory",
  "FontAddiFamilyPrimaryUrlRegular": "https://statics.addi.com/fonts/mallory/Mallory_Book.ttf",
  "FontAddiFamilyPrimaryUrlItalic": "https://statics.addi.com/fonts/mallory/Mallory_Book_Italic.ttf",
  "FontAddiFamilyPrimaryUrlBold": "https://statics.addi.com/fonts/mallory/Mallory_Bold.ttf",
  "FontAddiFamilyPrimaryUrlBoldItalic": "https://statics.addi.com/fonts/mallory/Mallory_Bold_Italic.ttf",
  "FontAddiFamilySecondaryName": "Inter",
  "FontAddiFamilySecondaryUrlRegular": "https://statics.addi.com/fonts/inter/Inter-Regular.ttf",
  "FontAddiFamilySecondaryUrlItalic": "https://statics.addi.com/fonts/inter/Inter-Italic.woff2",
  "FontAddiFamilySecondaryUrlBold": "https://statics.addi.com/fonts/inter/Inter-Bold.ttf",
  "FontAddiFamilySecondaryUrlBoldItalic": "https://statics.addi.com/fonts/inter/Inter-BoldItalic.woff2",
  "FontAddiFamilyIconsName": "addi-icon",
  "FontAddiFamilyIconsUrlRegular": "https://statics.addi.com/fonts/Icons/AddiIcon.ttf",
  "FontAddiWeightThin": 100,
  "FontAddiWeightExtraLight": 200,
  "FontAddiWeightLight": 300,
  "FontAddiWeightRegular": 400,
  "FontAddiWeightMedium": 500,
  "FontAddiWeightSemiBold": 600,
  "FontAddiWeightBold": 700,
  "FontAddiWeightExtraBold": 800,
  "FontAddiWeightBlack": 900,
  "FontAddiFormatWebOpenFontFormat": "woff",
  "FontAddiFormatWebOpenFontFormat2": "woff2",
  "FontAddiFormatTrueType": "truetype",
  "FontAddiFormatOpenType": "opentype",
  "FontAddiFormatEmbeddedOpenType": "embedded-opentype",
  "FontAddiFormatSvg": "svg"
};